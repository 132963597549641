const iwoodProjects = [       
    {
        slug: 'afluente-los-litres-1000',
        name: 'Afluente Los Litres 1000',
        type: 'Casas',
    },
    {
        slug: 'manantial-los-litres-1000',
        name: 'Manantial Los Litres 1000',
        type: 'Departamentos',
    },
    {
        slug: 'alto-nogales',
        name: 'Alto Los Nogales',
        type: 'Casas',
    },
    {
        slug: 'santa-maria-8700',
        name: 'Santa Maria 8700',
        type: 'Departamentos',
    },
]

export default iwoodProjects